import React from "react";
import { useLanguage } from "../Helpers/LanguageContext";

const MyTimeline = () => {
  const { language } = useLanguage();

  const eventsEn = [
    {
      date: "01/2025 - present...",  
      title: "Full Time Job",
      subtitle: "",
      description: "at Netwaciila",
    },
    
    {
      date: "In Progress...",  
      title: "toefl Preparation",
      subtitle: "",
      description: "",
    },
    {
      date: "06/2023 - Present",
      title: "Freelancing",
      subtitle: "",
      description: "",
    },
    {
      date: "08/2024",
      title: "Internship",
      subtitle: "",
      description: "at PCS AGRI",
    },
    {
      date: "12/2024",
      title: "TELC B1 Certificate",
      subtitle: "Score : 280/300",
      description: "",
    },
    {
      date: "06/2024",
      title: "Computer Science",
      subtitle: "",
      description: "University of Applied Science  - Settat",
    },
    {
      date: "01/2023",
      title: "Internship",
      subtitle: "",
      description: "at StartupStation",
    },
    {
      date: "06/2020",
      title: "High School ",
      subtitle: "",
      description: "Physics and Mathematics",
    },
  ];

  const eventsDe = [
    {
      date: "01/2025 - present...",  
      title: "Full Time Job",
      subtitle: "",
      description: "at Netwaciila",
    }
    ,
    {
      date: "In Arbeit...",
      title: "Toefl-Vorbereitung",
      subtitle: "",
      description: "",
    },
    {
      date: "01/2024 - Heute",
      title: "Freiberuflich",
      subtitle: "",
      description: "",
    },
    {
      date: "8/2024",
      title: "Praktikum",
      subtitle: "",
      description: "bei PCS AGRI",
    },
    {
      date: "12/2024",
      title: "TELC B1-Zertifikat",
      subtitle: "Ergebnis : 280/300",
      description: "",
    },
    {
      date: "06/2024",
      title: "Software ENtwickler",
      subtitle: "",
      description: "Hochschule für Angewandte Wissenschaften - Settat",
    },
    {
      date: "01/2023",
      title: "Praktikum",
      subtitle: "",
      description: "bei StartupStation",
    },
    {
      date: "06/2020",
      title: "Abitur",
      subtitle: "",
      description: "Physik und Mathematik",
    },
  ];

  const events = language === "en" ? eventsEn : eventsDe;

  return (
    <div className="timeline">
      {events.map((event, index) => (
        <div className="timeline-item" key={index}>
          <div className="timeline-content">
            <div className="idgnfvosofnv1">{event.title}</div>
            <div className="idgnfvosofnv2">{event.subtitle}</div>
            <div className="idgnfvosofnv3">{event.description}</div>
            <span className="timeline-date">{event.date}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyTimeline;
